<template>
  <tr :class="setRowClass" @click.stop="$emit('viewContent', contents)" style="cursor:pointer;">
    <td style="min-width: fit-content;">
      <div class="d-lg-flex d-block gap-2 align-items-center justify-content-center">
        <MDBBtn class="fw-bold m-md-auto mb-lg-0 mb-2" color="primary" size="sm"
          @click.stop="$emit('viewContent', contents)">
          <MDBIcon iconStyle="fas" icon="eye" style="margin-right: 1px;" />
          View
        </MDBBtn>
        <MDBBtn class="fw-bold m-md-auto" color="primary" size="sm" @click.stop="$emit('updateContent', contents)">
          <MDBIcon iconStyle="fas" icon="pencil-alt" style="margin-right: 1px;" />
          Update
        </MDBBtn>
      </div>
    </td>
    <td class="text-truncate" v-if="isAdmin" style="max-width: 200px;">{{ contents.userId ? contents.userFirstName + ' '
      + contents.userLastName : '—' }}</td>
    <td>
      <div class="d-flex flex-column justify-content-between text-center">
        <div>
          <MDBBadge v-if="contents.claimStatusName" :color="setBadgeColor" class="rounded-circle me-2" dot
            style="position: relative;margin-left:0;"></MDBBadge>
          <span>
            {{ contents.claimStatusName ? contents.claimStatusName : '—' }}
          </span>
        </div>
        <i v-if="contents.issuanceTime != 0" style="color: black;font-weight: bold;font-size: 12px;">
          {{ `${convertToHHMM(contents.issuanceTime)} minutes ago` }}
        </i>
      </div>
    </td>
    <td class="text-truncate" style="max-width: 200px;">
      <img v-if="contents.thumbnail" :src="contents.thumbnail" alt="thumbnail" style="width: 30px; height: 30px"
        class="rounded-circle me-2" />
      <img v-else src="@/assets/user-default.png" alt="thumbnail" style="width: 30px; height: 30px"
        class="rounded-circle me-2" />
      <span>
        {{ contents.youtubeChannel ? contents.youtubeChannel : '—' }}
      </span>
    </td>
    <td class="text-truncate" style="max-width: 200px;">
      <a :href="contents.youtubeUrl" target="_blank">
        {{ contents.youtubeUrl ? contents.youtubeUrl : '—' }}
      </a>
    </td>
    <td>{{ contents.timeStamp ? contents.timeStamp : '—' }}</td>
    <td class="text-truncate" style="max-width: 200px;">{{ contents.assetLabel ? contents.assetLabel : '—' }}</td>
    <td class="text-truncate" style="max-width: 200px;">
      <a :href="contents.dropboxLink" target="_blank">
        {{ contents.dropboxLink ? contents.dropboxLink : '—' }}
      </a>
    </td>
    <template v-if="isAdmin">
      <td class="text-truncate" style="max-width: 200px;">{{ contents.internalAssetDescription ?
        contents.internalAssetDescription : '—' }}</td>
      <td class="text-truncate" style="max-width: 200px;">{{ contents.clipTitle ? contents.clipTitle : '—' }}</td>
      <td>{{ contents.assetId ? contents.assetId : '—' }}</td>
      <td>{{ contents.dateClaimed ? parseDateLonger(contents.dateClaimed) : '—' }}</td>
      <td>{{ contents.claimantFirstName ? contents.claimantFirstName + ' ' + contents.claimantLastName : '—' }}</td>
      <td class="text-truncate" style="max-width: 200px;">{{ contents.remarks ? contents.remarks : '—' }}</td>
      <td class="text-truncate" style="max-width: 200px;">{{ contents.notes ? contents.notes : '—' }}</td>
    </template>
    <td>{{ contents.dateAdded ? parseDateLonger(contents.dateAdded) : '—' }}</td>
    <td v-if="isAdmin">{{ contents.dateUpdated ? parseDateLonger(contents.dateUpdated) : '—' }}</td>
  </tr>
</template>

<script setup>
import { defineProps, defineEmits, watch, ref, computed } from "vue";
import { parseDateLonger } from "@/helpers/parseDate";
import {
  MDBBtn,
  MDBIcon,
  MDBBadge
} from "mdb-vue-ui-kit";
import { useUserDetailStore } from "@/store/userDetailStore";

const props = defineProps({
  isAdmin: {
    type: Boolean,
    required: true,
  },
  contents: {
    type: Object,
    required: true,
    default: () => ({
      id: 0,
      userFirstName: '',
      userId: 0,
      userLastName: '',
      userAdded: 0,
      youtubeChannel: '',
      youtubeUrl: '',
      timeStamp: '',
      assetLabel: '',
      dropboxLink: '',
      internalAssetDescription: '',
      clipTitle: '',
      claimStatus: '',
      claimStatusName: '',
      assetId: '',
      claimant: '',
      claimantFirstName: '',
      claimantLastName: '',
      dateClaimed: null,
      remarks: '',
      notes: '',
      dateAdded: '',
      dateUpdated: '',
    }),
  }
});

defineEmits(["viewContent", "updateContent"]);

const userDetailStore = useUserDetailStore();
const setRowClass = ref();
watch(userDetailStore.userDetail, (newDetail) => {
  if (props.isAdmin) {
    if (props.contents.claimant && props.contents.claimant !== newDetail.id.toString()) {
      setRowClass.value = 'claimedByOthers';
    } else if (props.contents.claimant && props.contents.claimant === newDetail.id.toString()) {
      setRowClass.value = 'claimedByYou';
    }
  } else {
    if (props.contents.claimant) {
      setRowClass.value = 'claimed';
    }
  }
}, { immediate: true });
const setBadgeColor = computed(() => {
  if (props.contents.claimStatus === "1") {
    return 'success';
  } else if (props.contents.claimStatus === "2") {
    return 'danger';
  } else {
    return 'warning';
  }
})

function convertToHHMM(decimalMinutes) {
  const hours = Math.floor(decimalMinutes / 60);

  const minutes = Math.floor(decimalMinutes % 60);

  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

  return `${hours}:${formattedMinutes}`;
}
</script>

<style scoped>
td {
  text-wrap: nowrap;
  font-size: 13px;
}

tr {
  background: rgb(178, 190, 181);
}

.claimedByOthers {
  background-color: #f7e4cd;
}

.claimedByYou,
.claimed {
  background-color: #90EE90;
}

.btn-primary {
  background-color: var(--primary);
  display: flex;
  align-items: center;
  gap: 5px;
}

.action {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}
</style>
